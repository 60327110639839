import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from "mdbreact";

const ResourcePage = () => {
    return (
        <MDBContainer tag="section">
        <MDBRow className="mt-3 mb-3">
        <div class="col-12">
        <div class="card">
          <h3 class="card-header deep-orange darken-3 white-text text-uppercase">Resources</h3>
            <div class="card-body">
            <p>The following links are online resources referenced in the production of this project and which may help with classroom ideas.</p>
          <ul><li><a href="http://www.studentreportinglabs.com/" target="_blank" title="PBS NewsHour Student Reporting Labs">PBS NewsHour Student Reporting Labs</a></li>
          <li><a href="http://www.youtube.com/watch?v=4JXSbIeclwA" target="_blank" title="NewsHour Correspondents Address Reporting Labs Students">NewsHour Correspondents Address Reporting Labs Students</a></li>
          <li><a href="http://learning.blogs.nytimes.com/" target="_blank" title="New York Times' The Learning Network">New York Times' The Learning Network</a></li>
          </ul>
          <p>About journalism & standards (how to's, ethics, fair use, etc.):</p>
          <ul><li><a href="http://web.law.duke.edu/cspd/comics/" target="_blank" title="Tales from the Public Domain: BOUND BY LAW?">CSPD - Tales from the Public Domain: BOUND BY LAW?</a></li>
          <li><a href="http://www.loc.gov/teachers/usingprimarysources/" target="_blank" title="Library of Congress - Using Primary Sources">Library of Congress - Using Primary Sources</a></li>
          <li><a href="http://www.niemanstoryboard.org/2006/01/09/news-feature-v-narrative-whats-the-difference/" target="_blank" title="Nieman Storyboard- News Feature v. Narrative: What's the Difference?">Nieman Storyboard- News Feature v. Narrative: What's the Difference?</a></li>
          <li><a href="http://www.robertniles.com/journalism/" target="_blank" title="What is Journalism by Robert Niles">What is "Journalism" by Robert Niles</a></li>
          <li><a href="http://www.youtube.com/watch?v=1DNp2SYF85g" target="_blank" title="The Nation - Journalism as a Public Good ">The Nation- Journalism as a Public Good</a></li></ul>
          <p>About student journalism (how to start a campus newspaper, opportunities for students, etc.):</p>
          <ul><li><a href="https://lakotaeastsparkonline.com/" target="_blank" title="Lakota East High School - Spark Online">Lakota East High School - Spark Online</a></li>
          <li><a href="http://www.nytimes.com/learning/general/specials/weblines/index.html" target="_blank" title="Campus Weblines">Campus Weblines</a></li>
          <li><a href="http://quillandscroll.org/" target="_blank" title="Quill and Scroll">Quill and Scroll</a></li>
          <li><a href="http://cspa.columbia.edu/" target="_blank" title="Columbia Scholastic Press Association">Columbia Scholastic Press Association</a></li>
          <li><a href="http://www.studentpress.org/nspa/" target="_blank" title="National Scholastic Press Association">National Scholastic Press Association</a></li>
          <li><a href="http://jea.org/" target="_blank" title="http://jea.org/">Journalism Education Association</a></li>
          <li><a href="http://www.spj.org/students.asp" target="_blank" title="Society of Professional Journalists - For Students">Society of Professional Journalists - For Students</a></li>
          <li><a href="http://cronkite.asu.edu/experience/studentorganizations.php" target="_blank" title="Walter Cronkite School of Journalism and Mass Communication - Journalism Student Organizations">Walter Cronkite School of Journalism and Mass Communication - Journalism Student Organizations</a></li>
          <li><a href="https://www.necir.org/" target="_blank" title="New England Center for Investigative Reporting">New England Center for Investigative Reporting</a></li>
          </ul>
          <p>About the Common Core (standards, fiction vs non-fiction, etc.):</p>
          <ul><li><a href="http://www.corestandards.org/" target="_blank" title="Common Core State Standards">Common Core State Standards</a></li>
          <li><a href="http://opinionator.blogs.nytimes.com/2012/11/22/what-should-children-read/" target="_blank" title="The New York Times - Opinionator - What Should Children Read?">NYT&nbsp;- Opinionator - What Should Children Read?</a></li>
          <li><a href="http://www.edexcellence.net/ohio-policy/gadfly/2012/may-23/future-shock-early-common-core-implementation-lessons-from-ohio.html" target="_blank" title="Future Shock: Early Common Core implementation lessons from Ohio">Future Shock: Early Common Core implementation lessons from Ohio</a></li>
          <li><a href="http://learning.blogs.nytimes.com/2013/04/23/guest-post-primary-sources-meet-free-web-2-0-tools-for-common-core-learning/" target="_blank" title="The New York Times - Primary Sources Meet Free Web 2.0 Tools for Common Core Learning">NYT -&nbsp;Primary Sources Meet Free Web 2.0 Tools for Common Core Learning</a></li>
          <li><a href="http://learning.blogs.nytimes.com/2012/02/13/constructing-arguments-room-for-debate-and-the-common-core-standards/" target="_blank" title="The New York Times - Constructing Arguments: 'Room for Debate' and the Common Core Standards">NYT -&nbsp;Constructing Arguments: 'Room for Debate' and the Common Core Standards</a></li>
          <li><a href="http://learning.blogs.nytimes.com/2011/06/14/the-times-and-the-common-core-standards-reading-strategies-for-informational-text/" target="_blank" title="The New York Times - The Times and the Common Core Standards: Reading Strategies for 'Informational Text'">NYT -&nbsp;The Times and the Common Core Standards: Reading Strategies for 'Informational Text'</a></li>
          <li><a href="http://learning.blogs.nytimes.com/2011/12/12/compare-contrast-cause-effect-problem-solution-common-text-types-in-the-times/" target="_blank" title="The New York Times - Compare-Contrast, Cause-Effect, Problem-Solution: Common 'Text Types' in The Times">NYT&nbsp;-&nbsp;Compare-Contrast, Cause-Effect, Problem-Solution: Common 'Text Types' in The Times</a></li>
          <li><a href="http://www.slj.com/2013/01/standards/common-core/deconstructing-nonfiction-on-common-core/" target="_blank" title="School Library Journal - Deconstruction Nonfiction - On Common Core">School Library Journal - Deconstruction Nonfiction - On Common Core</a></li>
          <li><a href="http://www.thinkfinity.org/servlet/JiveServlet/previewBody/5344-102-1-9112/Examples%20of%20Text%20Features.pdf" target="_blank" title="Examples of Text Features">Examples of Text Features</a></li><li><a href="http://msjordanreads.com/2012/04/19/non-fiction-text-structures/" target="_blank" title="Non-Fiction Text Structures">Non-Fiction Text Structures</a></li></ul>
          <p>Lesson plans, etc.:</p>
          <ul><li><a href="http://learning.blogs.nytimes.com/2012/09/14/introducing-an-experiment-weekly-common-core-practice-prompts/" target="_blank" title="The New York Times - Introducing an Experiment: Weekly Common Core Practice Prompts">NYT<em> -&nbsp;</em>Introducing an Experiment: Weekly Common Core Practice Prompts</a></li>
          <li><a href="http://www.scholastic.com/teachers/lesson-plan/journalism-101" target="_blank" title="Scholastic - Journalism 101">Scholastic - Journalism 101</a></li>
          </ul>
            </div>
            </div>
            </div>
            </MDBRow>
        </MDBContainer>
    );
}

export default ResourcePage;
